@import '../../../../styles/base/breakpoints';
@import '../../../../styles/base/color';
@import '../../../../styles/base/typography';
@import '../../../../styles/mixins/mixins';

.historic-filters {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  
  @include sm {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap !important;
    align-items: flex-start;
    justify-content: space-between;
    height: 149px;
    gap: 5px;
  }

  @include md {
    gap: 2px;
    height: 128px;
  }
  
  @include lg {
    height: 80px;
    justify-content: flex-start !important;
    gap: 8px;
  }

  .btns-input-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 5px;
    gap: 8px;
    
    @include sm {
      width: 49%;
      height: 60px;
    }
    
    @include md {
      width: 33% !important;
      height: 40px;
      gap: 2px;
    }
    
    @include lg {
      width: 26.6% !important;
      height: 60px;
      gap: 8px;
    }
  
    @include custom(1300px) {
      width: 20% !important;
    }
    
    .btn {
      min-width: 49%;
      height: 40px;
      font-size: 14px !important;

      @include sm {
        border: 1px solid $primary;
      }

    }
  }
}

.each-container-select {
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  
  @include sm {
    width: 49% !important;
  }

  @include md {
    width: 33% !important;
  }

  @include lg {
    width: 23.6% !important;
  }

  @include custom(1300px) {
    width: 20% !important;
  }
  
  .MuiInputBase-root {
    width: 100%;
    height: 40px;
    font-size: 14px !important;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: $rubik !important;
    outline-color: $primary;
  }

  .MuiAutocomplete-root, .MuiInputBase-input {
    width: 100%;
    font-size: 14px !important;
    border-color: $primary !important;
    outline-color: $primary !important;
  }

  .MuiInputBase-input {
    font-family: $rubik;

    &::placeholder {
      opacity: 1;
    }
  }

  .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    font-family: $rubik;
  }

  &__placeholder {
    font-size: 14px !important;
    color: $primary !important;
    position: absolute;
    left: 15px;
    bottom: 9px;
    font-weight: 300;
    z-index: -1;
  }
}

.facturas-list-item {
  padding: 20px 0;
  
  .table-responsive {
    @include scrollbar($light-gray, $background);
    padding-bottom: 18px;
    font-size: 13px;

    @include xl {
      font-size: 14px;
    }

    svg:not(.icon-info-orange) {
      color: $primary;

      &:hover {
        color: $primaryHover;
      }
    }

    &__btn {
      padding: 5px 18px;
      color: #FFF;
      background-color: $primary;
      border-radius: 4px; 
      cursor: pointer; 
      font-size: 13px; 
      box-shadow: none !important;
      font-weight: 300 !important;

      @include xl {
        font-size: 14px;
      }

      &:hover {
        background-color: $primaryHoverBtn !important;
      }

      &:active {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
      }
    }
  }
}

.aprobar-baja-dialog__btns {
   .btn {
    width: 100px;
    font-size: 14px;
   }
}

.scroll-container {

  @include scrollbar($light-gray, $white, 8px);
}
.nueva-baja-form {
  &__container {
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;

    
    @include md {
      flex-direction: row !important;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      
      .form-group {
        width: 49.3% !important;

        @include custom(850px) {
          width: 49.4% !important;
        }

        @include custom(100px) {
          width: 49.5% !important;
        }
      }
    }
    
    input {
      border-color: $primary;
    }

    &--icons {
      color: $primary;
      font-size: 14px;

      &:hover {
        color: $primaryHover;
      }
    }
  }

  &__btns-container {
    gap: 6px;
    padding-top: 4px;

    .btn {
      font-size: 14px !important;
      @include sm {
        min-width: 120px !important;
      }
    }
  }
}

.btn.active {
  background-color: #007bff;
  border-color: #007bff;
}

.tabs {
  overflow: hidden;
  border-bottom: 1px solid #d1d1d1;
  background-color: $white;
  margin: 16px 0 18px;

  &__tab {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 12px 16px 8px;
    transition: 0.3s;
    font-size: 14px;
    color: $fontTertiary;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;

    &:hover {
      background-color: $gray;
    }

    &--selected {
      border-color: $infoSecondary;
      color: $infoSecondary;
    }
  }
}

.ofertas {
  @include mdMax {
    padding: 16px 14px !important;
  }

  .table-responsive {
    padding-bottom: 16px;
    @include scrollbar($primary, $white);
    width: 100%;

    thead, tbody {
      tr {
        width: 100%;
      }
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
  }

  &__card {
    width: 100%;
    background-color: $background;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 16px 16px;
    font-size: 13px;

    &--titles {
      text-transform: uppercase;
      padding-top: 9px;
      font-weight: 600;
      font-size: 12px;
    }
    
    &--titles, &--text {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;   

      &.second {
        grid-template-columns: 1fr 2.1fr;

        @include custom(600px) {
          gap: 13px;
        }
        @include md {
          gap: 16px;
        }
      }

      &--input {
        margin-top: -19px;
        width: 100%;

        input {
          max-width: 100px;
          @include custom(465px) {
            max-width: 119px;
          }

          @include sm {
            max-width: 150px;
          }
        }
      }
    }

    &--icons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $primary;
      gap: 6px;
      
      @include lgMax {
        margin-top: 16px;
        border-top: 1px solid $boxShadow;
        padding-top: 16px;
        gap: 15px;
      }

      button, span {
        border: 1px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--pdf, &--clock {
        color: $fontTertiary;
        cursor: pointer;
      }

      &--offer {
        color: $greenBtn;
        border-color: $greenBtn;
      }
      
      &--cancel {
        color: $danger;
        border-color: $danger;
      }

      &--info {
        color: $infoSecondary;
        border-color: $infoSecondary;
      }
      
      button {
        &:disabled {
          color: $disabled;
          border-color: $disabled;
        }
      }
    }
  }
}


