@import '../../../../styles/base/breakpoints';
@import '../../../../styles/base/color';
@import '../../../../styles/base/typography';
@import '../../../../styles/mixins/mixins';

.facturas {

  &__table-container {
    max-height: 50%;
    margin-top: 16px;
    margin-bottom: 32px;
    @include scrollbar($light-gray, $background);
  
    .bg-secondary {
        background-color: $light-gray !important;
      }
    
    .td-icon {
      
      &__warning {
        color: orange;
      }
        
      &__check {
        color: $primary !important;
      }
    }
  }
  
  &__title-inputs {
    @include subtitle();
    padding: 16px 0 10px;

    @include lg {
      padding-top: 0;
    }
  }

  .empresa-form {
    @include empresaFormBasics();

    @include lg {
      max-width: 950px;
    }

    .form-group {
      
      @include sm {
        width: 49.2%;
      }

      @include md {
        width: 32.6%;
      }

      .form-control {
        font-size: 13px !important;
        border-color: $primary;
      }
    }

    p {
      margin-bottom: 8px;
      font-size: 13px !important;
    }
  }

  &__btn-container {
    align-items: flex-end;
    justify-content: flex-end;
    gap: 6px;

    @include lg {
      max-width: 950px;
    }
    
    .btn {
      @include basicBtn();
    }
  }

  &__container-form {
  
    @include lg {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      &--form-box {
        max-width: 900px;
        border: 1px solid $primary;
        padding: 22px 20px;
        border-radius: 4px;
      }
    } 
  }
}


.MuiAutocomplete-popper {
  div {
    font-size: 13px !important;
    padding-left: 6px !important;
  }
}