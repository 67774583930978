@import '../../../../styles/base/breakpoints';
@import '../../../../styles/base/color';
@import '../../../../styles/base/typography';
@import '../../../../styles/mixins/mixins';

.mi-cuenta {

  @include lg {
    max-width: 800px;
    margin: auto;
    padding-top: 40px !important;
  }
  &__subtitle {
    font-size: 14px !important;
  }

  fieldset {
    padding: 16px 0;
    legend {
      color: $primary;
      font-weight: 600;
    }
  }

  &__mis-datos {
    div {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        display: flex;
        flex-direction: column;
        margin-bottom: 0 !important;
        font-size: 14px !important;
        color: $primary !important;
        
        span {
          min-height: 24px;
          color: $default;
          font-size: 14px !important;
          border-bottom: 1px solid $background;
        }
      }
    }
  }

  &__credenciales {
    legend {
      margin-bottom: 0 !important;
    }

    &--form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 16px;

      @include lg {
        gap: 10px;
      }
      
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        
        label {
          padding-top: 0.5rem;
          padding-bottom: 6px;
        }
      }

      &--form {
        @include lg {
          flex-direction: row !important;
          gap: 8px;
        }
      }
    }
  }
}