$primary: #215732;
$primaryHover:#28673c;
$primaryHoverBtn: #215833;
$primaryDisabled: #1f46297f;
$primaryLight: #DAF9E4;
$default: #212529;
$fontPrimary: #000;
$fontSecondary: #303030;
$fontTertiary: #525252;
$fontQuaternary: #a8a8a8;
$background: #F5F5F7;
$off-white: #f4f4f4;
$gray:#f8fafc;
$white: #FFF;
$light-gray: #9e9b9b;
$bgHover: #e7e7e732;
$gradientGreen: linear-gradient(#008255, #215732);

$green: #29a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #62B1F6;
$infoSecondary: rgb(25, 118, 210);

$checkBox: #446c3c;
$checkBoxCheked: #285627;
$checkBoxDisabled: #6d8b6b;

$boxShadow: rgba(171, 171, 171, .25);

$greenBtn: #2b843f;
$disabled: #41414133;

$btnFotos: #E8E8E8;
$skeletonPrimary: #f9f9f9;
$skeletonSecondary: #f4f4f4;