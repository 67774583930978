@import '../../../../../../../styles/base/color';
@import '../../../../../../../styles/base/breakpoints';

.mySwiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 0 24px 16px;
    @include sm {
        padding: auto;
        margin: auto;
    }
    
    & > div {
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    &__img {
        img {
            border-radius: 6px;
        }
        .img-default {
            width: 100% !important;
        }
    }
}

.swiper {
    height: 100%;
}

.swiper-slide {
    align-self: center;
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 100% !important;
    
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}