@import '../../../../styles/base/breakpoints';
@import '../../../../styles/base/color';
@import '../../../../styles/base/typography';
@import '../../../../styles/mixins/mixins';

.desguaces {
  &__subtitle {
    @include subtitle();
    padding: 9px 0;
  }

  .empresa-form {
    @include empresaFormBasics();

    input.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      color: #212529;
      font-family: $rubik;
    }

    .form-group {
      @include sm {
        width: 49.2%;
      }

      @include md {
        width: 32.6%;
      }

      @include xl {
        width: 24.4%;
      }

      @include custom(1350px) {
        width: 24.5%;
      }

      @include custom(1600px) {
        width: 24.6%;
      }
    }
  }

  .btn-primary {
    margin-top: 8px;
    @include basicBtn();
  }
  
}