@import '../../../../../../../styles/base/color';
@import '../../../../../../../styles/base/breakpoints';
@import '../../../../../../../styles/mixins/mixins';

.skeleton-each-result {
  width: 100%;
  height: 280px;
  display: flex;
  padding-bottom: 14px;

  &.mutua {
    height: 250px;
    @include lg {
      height: 70px;
    }
  }

  @include lg {
    height: 70px;
  }


  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $background;
    gap: 10px;
    padding: 12px;
    
    @include lgMax {
      border-radius: 6px;
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 9px;
      padding: 16px 16px 16px;
    }

    @include lg {
      display: flex;
    }

    &--first {
      @include skeletonBasics(11px, 70px, 3px);

      @include lg {
        &.id {
          width: 12px;
        }
      }
    }

    &--second {
      @include skeletonBasics(11px, 90px, 3px);
      &.mutua {
        width: 180px;
      }
    }
    
    &--first, &--second {
      &.text {
        @include lgMax {
          margin-bottom: 6px;
        }
      }
    }
  }

  &__container-btns {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 8px 12px;
    
    @include lgMax {
      height: 50px;
      width: 100%;
      gap: 15px;
      grid-column: 1/4;
      border-top: 1px solid $gray;
      margin-top: 9px;
      padding: 10px 0 0;
    }

    &--btn {
      padding: 0 !important;
      background-color: $btnFotos !important;
      @include skeletonBasics(35px, 35px, 6px);
    }
  }
}

.skeleton-each-result-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}